import React from "react"

import Layout from "@components/layout"
import OffSiteLink from '@components/off-site-link'
import SEO from "@components/seo"

const PressPage = () => (
  <Layout bodyClassName='press'>
    <SEO 
      title="Press"
      description="Resources about Harper for press and media" 
    />

    <section className="stripe stripe--hero stripe--centered">
      <div className="container container--lg">
        <div className="copy">
          <h1 className="pageTitle">Press &amp; Media</h1>
        </div>
      </div>
    </section>

    <section className="stripe stripe--centered">
      <div className="container container--lg">
        <div className="copy">
          <h2 className="sectionTitle">Writing about Harper</h2>
          <h3 className="listTitle">What is Harper?</h3>
          <p className="prose">Harper is an app to teach anyone how to be a great dog parent from the comfort and safety of home. You can learn to train, parent, play, and bond with your dog using our 100+ positive reinforcement training games and expert guides in just a few minutes a day. Harper is currently available for iPhone and will expand to other platforms.</p>
          <h3 className="listTitle">How does it work?</h3>
          <p className="prose">Harper pairs evidence-based positive dog training with human behavior design to help people develop lasting habits that empower both dogs and humans. You can train loose leash walking, socialization, crate training, and more.</p>
          <p className="prose">Every course and game in Harper is designed in collaboration with animal behaviorists and leading force-free trainers. Plus, progress through Harper will set up any pup to ace the American Kennel Club’s Canine Good Citizen Test, the gold standard for measuring a well-adjusted dog.</p>
          <h3 className="listTitle">Who built Harper?</h3>
          <p className="prose">Harper is built in Cincinnati, OH by Robin Daugherty &amp; Nick Cramer&mdash;friends and dog parents who are building it, first and foremost, for their own pups.</p>
        </div>
      </div>
    </section>

    <section className="stripe stripe--centered stripe--alt">
      <div className="container container--lg">
        <div className="copy">
          <h2 className="sectionTitle">Media Kit &amp; Inquiries</h2>
          <p className="prose">For further media inquiries, please contact<br/><a href="mailto:nick@harper.dog">nick@harper.dog</a></p>
          <OffSiteLink href="https://www.notion.so/Media-Kit-648ccc206cce44269ff2650d6bd0d715" className="btn btn--primary">Download media kit</OffSiteLink>
        </div>
      </div>
    </section>
  </Layout>
);

export default PressPage
